@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700;900&display=swap';

body {
  margin: 0;
  font-family: Monserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[class^='corner'] {
  --top-left: 0 0;
  --top-right: 100% 0;
  --bot-right: 100% 100%;
  --bot-left: 0 100%;
  --corner: 50px;
  clip-path: polygon(var(--top-left), var(--top-right), var(--bot-right), var(--bot-left));
}

.corner {
  $from-end: calc(100% - var(--corner));

  &-TL {
    --top-left: 0 var(--corner), var(--corner) 0;
  }

  &-TR {
    --top-right: #{$from-end} 0, 100% var(--corner);
  }

  &-BR {
    --bot-right: 100% #{$from-end}, #{$from-end} 100%;
  }

  &-BL {
    --bot-left: var(--corner) 100%, 0 #{$from-end};
  }
}
